import Instance from '../common/request/requestService.js'

//会议管理
export default {
  // 获取会议列表
  getMettingList(params){
    return Instance.paramsGet(`/api/Mobile/GetMettingList`, params);    
  },
  // 获取会议详情
  getMettingDetail(id){
    return Instance.paramsGet(`/api/Mobile/GetMettingDetail/${id}`);    
  },

  
  //获取会议状态下拉
  getMettingStatusList: (params) => {
    return Instance.paramsGet('/api/Metting/GetMeetStatus', params);
  },
  //供需会议列表
  getList: (params) => {
    return Instance.paramsGet('/api/Metting/GetList', params);
  },
  //新增会议
  addMetting(params){
    return Instance.post('/api/Metting/Add', params);
  },
  //编辑会议
  editMetting(params){
    return Instance.post('/api/Metting/Update', params);
  },
  //删除会议
  delMetting(params){
    return Instance.deletes('/api/Metting/Del', params);
  },
  //上传图片和附件
  uploadFile(params){
    return Instance.postFormData('/api/Metting/UploadFile/meetUploadFile', params);
  },

}