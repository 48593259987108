import Instance from '../common/request/requestService.js'

//报告管理
export default {
  //获取报告类型下拉
  getReportTypeList: (params) => {
    return Instance.paramsGet('/api/Report/GetReportDropDown', params);
  },
  //供需报告列表
  getReportList: (params) => {
    return Instance.paramsGet('/api/Report/GetList', params);
  },
  //新增报告
  addReport(params){
    return Instance.post('/api/Report/Add', params);
  },
  //编辑报告
  editReport(params){
    return Instance.post('/api/Report/Update', params);
  },
  //删除报告
  delReport(params){
    return Instance.deletes('/api/Report/Del', params);
  },
  //上传图片和附件
  uploadFile(params){
    return Instance.postFormData('/api/Report/UploadFile/reportUploadFile', params);
  },
  //报告列表
  GetReportArr: (params) => {
    return Instance.paramsGet(`/api/Mobile/GetReportList`, params);
  },
  // 获取报告详情
  getReportDetail(id){
    return Instance.paramsGet(`/api/Mobile/GetReportDetail/${id}`);    
  },

}