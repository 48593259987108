import Instance from '@/common/request/requestService'

//供需货
export default {
  // 列表
  GetSupplyInfoList: (params) => {
    return Instance.paramsGet(`/api/Mobile/GetSupplyInfoList`, params);
  },
  //详情
  GetSupplyInfoDetail: (params) => {
    return Instance.paramsGet(`/api/Mobile/GetSupplyInfoDetail/${params}`);
  },
}