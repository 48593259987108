<template>
	<div>
		<el-container>
			<el-header class="d_header" height='80px'>
				<el-row class="d_nav_box">
					<div class="d_logo">
						<img alt="磐石酚享" src="../assets/images/logo.jpg" />
					</div>
					<div class="d_nav">
						<el-menu :default-active="onRoutes" class="el-menu-demo" mode="horizontal" router>
							<el-menu-item index="/">首页</el-menu-item>
							<el-submenu index="/aboutUs">
								<template slot="title">走进磐石</template>
								<el-menu-item index="/about"  class="nextTitlt">企业介绍</el-menu-item>
								<el-menu-item index="/culture"  class="nextTitlt">企业文化</el-menu-item>
								<el-menu-item index="/process"  class="nextTitlt">发展历程</el-menu-item>
							</el-submenu>
							<el-menu-item index="/product">产品中心</el-menu-item>
							<el-menu-item index="/report">报告</el-menu-item>
							<el-menu-item index="/metting">会议</el-menu-item>
							<el-submenu index="/marketprice">
								<template slot="title">市场价格</template>
								<el-menu-item index="/market/01" class="nextTitlt">石油苯酚</el-menu-item>
								<el-menu-item index="/market/02"  class="nextTitlt">粗酚现货</el-menu-item>
								<el-menu-item index="/market/03"  class="nextTitlt">酚油现货</el-menu-item>
								<el-menu-item index="/market/04"  class="nextTitlt">煤焦油现货</el-menu-item>
								<el-menu-item index="/market/05"  class="nextTitlt">间对甲酚现货</el-menu-item>
								<el-menu-item index="/market/06"  class="nextTitlt">邻甲酚现货</el-menu-item>
								<el-menu-item index="/market/07"  class="nextTitlt">苯酚现货</el-menu-item>
								<el-menu-item index="/market/08"  class="nextTitlt">甲酚现货</el-menu-item>
								<el-menu-item index="/market/09"  class="nextTitlt">漆包线现货</el-menu-item>
								<el-menu-item index="/market/10"  class="nextTitlt">酚钠盐现货</el-menu-item>
							</el-submenu>
							<el-submenu index="/ContactUs">
								<template slot="title" >联系我们</template>
								<el-menu-item index="/contact"  class="nextTitlt">联系方式</el-menu-item>
								<el-menu-item index="/messageBoard"  class="nextTitlt">在线留言</el-menu-item>
								<!-- <el-menu-item index="/hire"  class="nextTitlt">招贤纳士</el-menu-item> -->
							</el-submenu>
						</el-menu>
					</div>
					<div class="d_user">
						<el-dropdown style="line-height: 0;">
							<span class="el-dropdown-link">
								{{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>
								</el-dropdown-item>
								<el-dropdown-item>
									<!--  ? '退出登录' : '登录' -->
									<span @click="goLogin"> {{ loginType == undefined ? '登录' : '退出登录' }} </span>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</el-row>
			</el-header>
			<el-main class="d_container">
				<keep-alive>
					<router-view v-if="$route.meta.keepAlive"></router-view>
				</keep-alive>
				<router-view v-if="!$route.meta.keepAlive"></router-view>
			</el-main>
			<FooterView />
		</el-container>
	</div>
</template>

<script>
import FooterView from '@/components/FooterView.vue'

export default {
	name: 'HeaderView',
	components: {
		FooterView
	},
	data() {
		return {
			loginType: localStorage.getItem("userName") ? localStorage.getItem("userName") : undefined,
			userName: JSON.parse(localStorage.getItem("userName")) ? `欢迎您，${JSON.parse(localStorage.getItem("userName"))}` : `欢迎您，请登录`
		}
	},
	computed: {
		onRoutes() {
			return this.$route.path;
		},
	},
	methods: {
		goLogin() {
			this.$router.push({
				path: '/login'
			})
			localStorage.clear()
			// this.localStorage.removeItem('userName')
			// this.localStorage.removeItem('accessToken')
			// this.localStorage.removeItem('tokenType')
		}
	}
}
</script>

<style>
.el-menu--popup{
	left: -40px;
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title{
	text-align: center;
}
</style>
<style scoped lang="less">
.d_header {
	height: 80px;
	min-width: 1440px;
	width: 100%;
	margin: auto;
	position: fixed;
	top: 0px;
	z-index: 100;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
}

.d_nav_box {
	width: 80%;
	display: flex;
	align-content: center;
}

.d_container {
	padding-top: 80px !important;
}
.d_container>div{
	margin-bottom: 100px;
}
.d_logo {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
	overflow: hidden;
}

.d_logo img {
	height: 200%;
	margin-top: 10%;
}

.d_nav {
	flex: 1;
	display: flex;
	align-items: center;
}

.el-menu.el-menu--horizontal {
	border: none;
}

/deep/.el-submenu__title,
.el-menu-item {
	font-size: 20px;
	color: #000 !important;
	font-weight: bold;
}
/deep/.el-menu--horizontal>.el-submenu .el-submenu__title{
	font-size: 20px;
	color: #000;
	font-weight: bold;
}
.nextTitlt{
	font-size: 18px;
	font-weight: 400;
	padding: 5px 10px 10px !important;
	height: inherit !important;
	// border-bottom: 1px solid #909399;
	position: relative;
}
.nextTitlt::after{
	content: '';
	position: absolute;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	width: 80%;
	height: 1px;
	background-color: #EBEEF5;
}
.nextTitlt:last-child::after{
	display: none;
}
.el-header {
	background-color: white;
	color: #333;
	text-align: center;
	line-height: 80px;
	padding: 0 20px;
}

.el-aside {
	background-color: #d3dce6;
	color: #333;
}

.el-main {
	/* background-color: #e9eef3; */
	min-height: 1000px;
	color: #333;
	padding: 20px 0 0;
	overflow: initial;
}

.option1 {
	opacity: 0;
}

.title_ {
	font-size: 22px;
	font-weight: bold;
}

.nav_title {
	cursor: pointer;
}

.nav_title:hover {
	color: #ce131b;
}
</style>