export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: '磐石酚享',
  /**
   * @description api请求基础路径
   */
  baseUrl: {
    development: '/api',
    test: 'https://',
    production: 'http://api.pansfx.com'
  },
}