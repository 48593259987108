/**
 * 在线留言模块接口列表
 */
import Instance from '@/common/request/requestService'

export default {
  //新增留言
  addLeaveWord(data){
    return Instance.post('/api/InfoCollect/Add', data);
  },
};