import Vue from 'vue'
import router from '@/router'
import axios from 'axios'
import config from '@/config'
import qs from 'qs';

//接口
let baseUrl = config.baseUrl.production;


let token = ''

/** 
 * 跳转登录页
 */
const toLogin = () => {
  router.replace({
    path: '/login',
  });
}

// 创建axios实例
var instance = axios.create({
  // timeout: 1000 * 120,
});

/* 请求拦截器 */
// let cancel; //取消请求
let isRefreshing = true; //防止并发请求时，重复提示登陆

instance.interceptors.request.use(
  config => {
    
    token = localStorage.accessToken;
    token && (config.headers.Authorization = 'bearer ' + token);

    return config;
  },
  error => {
    return Promise.error(error);
  })
// 响应拦截器
instance.interceptors.response.use(
  response => {
    const res = (response.config && response.config.url) ? response : response.data
    if (res.status === 200) {
      if (res.data && res.data.status == '500') {
        Vue.prototype.$message.error(res.data.errorMsg || res.data.msg || 'error');
        return Promise.reject(res);
      } else {
        return res.data
      }
    } else {
      Vue.prototype.$message.error(res.errorMsg || 'error');
      return Promise.reject(res);
    }
  },
  error => {
    const {
      response
    } = error;

    if (response) {
      //目前token过期 401
      if (response.status == 401) {
        if(isRefreshing) {
          isRefreshing = false
          Vue.prototype.$confirm('登录已超时，请重新登录。', '温馨提示', {
            confirmButtonText: '确定',
            showClose: false,
            showCancelButton:false,
            closeOnClickModal:false,
            closeOnPressEscape:false,
            type: 'warning'
          }).then(() => {
            toLogin()
            isRefreshing = true
            // location.reload(true);
          }).catch(() => {
            isRefreshing = true
          });
          return Promise.reject(response);
        }
      } else {
        Vue.prototype.$message.error(response.data.msg || '服务器繁忙，请稍后再试～');
        return Promise.reject(response);
      }

    } else {
      Vue.prototype.$message.error('网络服务异常～');
    }
  }
);

// 创建axios实例
var loginInstance = axios.create({
  // timeout: 1000 * 12,
});

/* 请求拦截器 */
loginInstance.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = "application/json"
    return config;
  },
  error => {
    return Promise.error(error);
  })
// 响应拦截器
loginInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const {
      response
    } = error; //解构

    if (response) {

      Vue.prototype.$message.error(response.data.errorMsg || '服务异常～');

      return Promise.reject(response);
    } else {
      Vue.prototype.$message.error('网络服务异常～');
    }
  }
);

function getUrl(url) {
  let newUrl
  switch (url.split('/')[1]) {
    case 'api':
      newUrl = `${baseUrl}${url}`
      break;
    default:
      newUrl = `${baseUrl}${url}`
      break;
  }
  return newUrl
}

const postFormData = (url, params) => {
  return new Promise((resolve, reject) => {
    instance({
      headers: {
        'content-type': 'multipart/form-data'
      },
      method: 'post',
      url: getUrl(url),
      data: params
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}
const blobGet = (url, params) => {
  return new Promise((resolve, reject) => {
    instance({
      responseType: 'blob',
      method: 'get',
      url: getUrl(url),
      params: params
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}

function getParams(params) {
  if (Object.prototype.toString.call(params) === '[object FormData]') {
    console.log(params);
  } else {
    for (const key in params) {
      if (params[key] === '') {
        // delete params[key]
      }
    }
  }
  return params
}

const get = (url, params) => {
  return new Promise((resolve, reject) => {
    instance.get(getUrl(url), getParams(params))
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}
const paramsGet = (url, paramsdata) => {
  return new Promise((resolve, reject) => {
    instance.get(getUrl(url), {
      params: paramsdata
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
  })
}

const post = (url, params, config) => {
  return new Promise((resolve, reject) => {
    instance.post(getUrl(url), getParams(params), config)
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err)
    })
  });
}

const postForm = (url, params) => {
  return new Promise((resolve, reject) => {
    instance({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: 'post',
      url: getUrl(url),
      data: qs.stringify(params)
    })
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err)
    })
  });
}

const deletes = (url, params) => {
  return new Promise((resolve, reject) => {
    instance.delete(getUrl(url)+'/'+params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}
const login = (url, params) => {
  return new Promise((resolve, reject) => {
    loginInstance.post(getUrl(url), getParams(params))
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}
//下载excel
const downloadExcel = (url, data, excelName = 'file', img, type) => {
  return new Promise((resolve, reject) => {
    axios({
      url: getUrl(url),
      method: "post",
      data,
      responseType: "blob",
      headers: {
        Authorization: `bearer ${localStorage.accessToken}`
      }
    })
      .then(da => {
        if (da.data.size == 0) {
          resolve(false)
        } else {
          const blob = new Blob([da.data]);
          const fileName = `${excelName}.${img ? 'png' : type ? type : 'xls'}`;
          const linkNode = document.createElement("a");
          linkNode.download = fileName;
          linkNode.style.display = "none";
          linkNode.href = URL.createObjectURL(blob);
          document.body.appendChild(linkNode);
          linkNode.click();
          URL.revokeObjectURL(linkNode.href);
          document.body.removeChild(linkNode);
          resolve(true)
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
//下载excel
const orderDownloadExcel = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: "get",
      params: params,
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`
      }
    })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error);
      });
  });
}

const put = (url, params, config) => {
  return new Promise((resolve, reject) => {
    instance.put(getUrl(url), getParams(params), config)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}

const patch = (url, params, config) => {
  return new Promise((resolve, reject) => {
    instance.patch(getUrl(url), getParams(params), config)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}

export default {
  postFormData,
  blobGet,
  login,
  get,
  post,
  postForm,
  deletes, //删除接口
  downloadExcel,
  paramsGet,
  orderDownloadExcel,
  put,
  patch
}