/** 
 * api接口的统一出口
 */

const req = require.context('./', false, /\.js$/)
let item = {}
req.keys().forEach(da => {
  const k = da.match(/(.*\/)*([^.]+).*/)[2]
  if (k !== 'index') {
    item[k] = req(da).default
  }
})
export default item