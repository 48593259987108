import Instance from '../common/request/requestService.js'

//产品管理
export default {
   // 获取产品
   GetProdList(params){
    return Instance.paramsGet(`/api/Mobile/GetProdList`, params);    
  },
  // 获取产品列表
  GetDocInfoList(data){
    return Instance.post(`/api/Mobile/GetDocInfoList`, data);    
  },
   // 获取产品详情
   GetDocInfoDetail(id){
    return Instance.paramsGet(`/api/Mobile/GetDocInfoDetail/${id}`);    
  },
  


  //行业管理列表
  getHangYeList: (params) => {
    return Instance.paramsGet('/api/Industry/GetList', params);
  },
  //新增行业
  addHangye(params){
    return Instance.post('/api/Industry/Add', params);
  },
  //编辑行业
  editHangye(params){
    return Instance.post('/api/Industry/Update', params);
  },
  //删除行业
  delHangye(params){
    return Instance.deletes('/api/Industry/Del', params);
  },
  //获取行业下拉
  getHangyeDataList: (params) => {
    return Instance.paramsGet('/api/Industry/GetIndustryDown', params);
  },
  //获取产品下拉
  getChanpinDataList: (params) => {
    return Instance.paramsGet('/api/ProdClass/GetProdDown', params);
  },
  //产品文章列表
  getWenzhangList: (params) => {
    return Instance.post('/api/DocInfo/GetList', params);
  },
  //新增产品文章
  addWenzhang(params){
    return Instance.post('/api/DocInfo/Add', params);
  },
  //编辑产品文章
  editWenzhang(params){
    return Instance.post('/api/DocInfo/Update', params);
  },
  //删除产品文章
  delWenzhang(params){
    return Instance.deletes('/api/DocInfo/Del', params);
  },
  //产品列表
  getChanpinList: (params) => {
    return Instance.paramsGet('/api/ProdClass/GetList', params);
  },
  //新增产品
  addChanpin(params){
    return Instance.post('/api/ProdClass/Add', params);
  },
  //编辑产品
  editChanpin(params){
    return Instance.post('/api/ProdClass/Update', params);
  },
  //删除产品
  delChanpin(params){
    return Instance.deletes('/api/ProdClass/Del', params);
  },
  //广告列表
  getAdList: (params) => {
    return Instance.paramsGet('/api/Ad/GetList', params);
  },
  //新增广告
  addAd(params){
    return Instance.post('/api/Ad/Add', params);
  },
  //编辑广告
  editAd(params){
    return Instance.post('/api/Ad/Update', params);
  },
  //删除广告
  delAd(params){
    return Instance.deletes('/api/Ad/Del', params);
  },
  //关联信息列表
  getRelatedInfoList: (params) => {
    return Instance.paramsGet('/api/RelatedInfo/GetList', params);
  },
  //新增关联信息
  addRelatedInfo(params){
    return Instance.post('/api/RelatedInfo/Add', params);
  },
  //编辑关联信息
  editRelatedInfo(params){
    return Instance.post('/api/RelatedInfo/Update', params);
  },
  //删除关联信息
  delRelatedInfo(params){
    return Instance.deletes('/api/RelatedInfo/Del', params);
  },
}