<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);
    next();
  }
};
</script>

<style>
	*{
		margin: 0;
		padding: 0;
	}
</style>
