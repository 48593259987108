/**
 * 登录 模块接口列表
 */
import Instance from '../common/request/requestService.js'// 导入http中创建的axios实例
// import qs from 'qs'; // 根据需求是否导入qs模块

//路由地址集合
const urls = {
    signIn: "/api/adminlogin/AdminLogin/login",
    refreshToken: "/of/order/page",
    getUserInfo: "/user/system/core_user/info",
    login:'/api/user/userLogin/login',
}

//接口请求封装
const loginApi = {
    //登录
    getUserInfo(params) {
        return Instance.post(`${urls.getUserInfo}`, params);
    },
    signIn(params) {
        return Instance.login(`${urls.signIn}`, params);
    },
     // 登录
    postMobelLogin(data) {
      return Instance.post(`${urls.login}`, data);
    },
}

export default loginApi;

// login模块两个接口由于需要加header信息，所以放在了页面内调用（login.vue）