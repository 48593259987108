import Instance from '@/common/request/requestService'

export default{
  // 用户注册
  PostAddCompany(data){
    return Instance.post(`/api/user/ComanyRegister`, data);    

  },
  // 获取验证码
  GetVerifyCode(data){
    return Instance.post(`/api/user/GetVerifyCode`, data);    
  }
}
