/**
 * 系统管理模块接口列表
 */
import Instance from '@/common/request/requestService'

export default {
  //获取关于我们
  getAboutUs(){
    return Instance.get(`/api/AboutUs/Get`);
  },
  //编辑关于我们
  editAboutUs(params){
    return Instance.post('/api/AboutUs/UpdateOrAdd', params);
  },
  //浏览记录列表
  getBrowseRecordList: (params) => {
    return Instance.paramsGet('/api/BrowseRecord/GetList', params);
  },
  //用户列表
  getUserList: (params) => {
    return Instance.paramsGet('/api/SysUser/GetList', params);
  },
  //新增用户
  addUser(params){
    return Instance.post('/api/SysUser/Add', params);
  },
  //编辑用户
  editUser(params){
    return Instance.post('/api/SysUser/Update', params);
  },
  //删除用户
  delUser(params){
    return Instance.deletes('/api/SysUser/Del', params);
  },
};