import Instance from '../common/request/requestService.js'

//市场价格管理
export default {
  // 市场价格列表
  getBigDataList(params){
    return Instance.paramsGet(`/api/Mobile/GetBigDataList`, params);    
  },
  //导出
  getExportData(params){
    return Instance.blobGet(`/api/Mobile/ExportBigData/export`, params);    
  },
}