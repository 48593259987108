<template>
  <div>
    <div class="footer">
      <div ></div>
      <!-- <div class="f_item"><img class="logo" alt="磐石酚享" src="../assets/images/logo3.png" /></div> -->
      <div class="f_item">
        <span class="f_nav" @click="$router.push('/about')">企业介绍</span>
        <span class="f_nav" @click="$router.push('/culture')">企业文化</span>
        <span class="f_nav" @click="$router.push('/process')">发展历程</span>
        <span class="f_nav" @click="$router.push('/contact')">联系方式</span>
        <!-- <span class="f_nav" @click="$router.push('/hire')">招贤纳士</span> -->
      </div>
      <!-- <div class="f_item">
        
      </div> -->
      <div class="f_item"><img alt="磐石酚享" src="../assets/pcwximg.jpg" /></div>
      <!-- <div class="f_item">XXXXX网站 XXXXX</div>
      <div class="f_item">公网安备XXXXX号</div> -->
      <div class="f_item">北京磐石酚享商务咨询有限公司 版权所有</div>
      <div class="f_item" v-html="info"></div>
      <!-- <div class="f_item red">XXXXX</div> -->
    </div>
  </div>
</template>

<script>
export default {
	name: 'FooterView',
	data(){
		return {
			info:''
		}
	},
	computed: {
	},
  mounted(){
    this.getMineInfo()
  },
  methods:{
    getMineInfo() {
			this.$api.companyInfoApi.getMine().then(res => {
				if (res.status == 200) {
					this.info = res.response.ContactUs
				}
			}).catch(error => {
				console.log(error);
			})
		},
  }
}
</script>

<style lang="less">
.footer {
	background-color: #000;
	color: #fff;
	text-align: center;
  padding: 20px 0 ;
}
.f_item {
  font-size: 14px;
  margin-bottom: 4px;
}
.f_item img {
  height: 100px;
  margin-top: 20px;
}
.f_nav{
  font-size: 14px;
  margin-right: 16px;
  cursor: pointer;
}
.f_nav:hover{
  text-decoration: underline;
}
.red{
  color: red !important;
  font-size: 20px !important;
}
</style>