import Instance from '../common/request/requestService.js'

//大数据
export default {
  //大数据价格列表
  getPeiceList: (params) => {
    return Instance.paramsGet('/api/BigData/GetList', params);
  },
  //新增大数据价格
  addPeice(params){
    return Instance.post('/api/BigData/Add', params);
  },
  //编辑大数据价格
  editPeice(params){
    return Instance.post('/api/BigData/Update', params);
  },
  //删除大数据价格
  delPeice(params){
    return Instance.deletes('/api/BigData/Del', params);
  },
  //下载模板
  downloadTemplate(params){
    return Instance.paramsGet('/api/BigData/download/download', params);
  },
  //大数据导出
  exportBigData(params){
    return Instance.blobGet('/api/BigData/ExportBigData/export', params);
  },
  //大数据导入Excel
  importFile(params){
    return Instance.postFormData('/api/BigData/ImportFile/ImportFile', params);
  },
}