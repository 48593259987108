import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import VueScrollTo from 'vue-scrollto';

Vue.use(VueRouter)

const routes = [
	{
		path:'/',
		component: HomeView,
		props:true,
		children:[
			{
				path:'/',
				component:() => import("@/views/index.vue"),
			},
			{
				path:'/product',
				name:'product',
				component:() => import("@/views/product.vue"),
				meta: {
					keepAlive: true,
				}
			},
			{
				path:'/details',
				name:'details',
				component:() => import("@/views/details.vue"),
			},
			{
				path:'/my',
				name:'my',
				component:() => import("@/views/my.vue")
			},
			{
				path: '/market/:id',
				// path:'/market',
				name:'market',
				component:() => import("@/views/market.vue")
			},
			{
				path:'/report',
				name:'report',
				component:() => import("@/views/report.vue"),
				meta: {
					keepAlive: true,
				}
			},
			{
				path:'/reportDetail',
				name:'reportDetail',
				component:() => import("@/views/reportDetail.vue")
			},
			{
				path:'/reportRead',
				name:'reportRead',
				component:() => import("@/views/reportRead.vue")
			},
			{
				path:'/suppNeed',
				name:'suppNeed',
				component:() => import("@/views/suppNeed.vue")
			},
			{
				path:'/suppNeedDetail',
				name:'suppNeedDetail',
				component:() => import("@/views/suppNeedDetail.vue")
			},
			{
				path:'/about',
				name:'about',
				component:() => import("@/views/AboutView.vue")
			},
			{
				path:'/metting',
				name:'metting',
				component:() => import("@/views/metting.vue"),
				meta: {
					keepAlive: true,
				}
			},
			{
				path:'/mettingDetail',
				name:'mettingDetail',
				component:() => import("@/views/mettingDetail.vue")
			},
			{
				path:'/culture',
				name:'culture',
				component:() => import("@/views/culture.vue")
			},
			{
				path:'/process',
				name:'process',
				component:() => import("@/views/process.vue")
			},
			{
				path:'/contact',
				name:'contact',
				component:() => import("@/views/contact.vue")
			},
			{
				path:'/messageBoard',
				name:'messageBoard',
				component:() => import("@/views/messageBoard.vue")
			},
			{
				path:'/hire',
				name:'hire',
				component:() => import("@/views/hire.vue")
			},
		]
	},
	{
		path:'/login',
		name:'login',
		component:() => import("@/views/login.vue")
	},
	{
		path:'/register',
		name:'register',
		component:() => import("@/views/register.vue")
	}
]
// 设置vue-scrollto  解决切换页面，定位不在顶端问题
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: function(element) {
    // scrolling started
  },
  onDone: function(element) {
    // scrolling is done
  },
  onCancel: function() {
    // scrolling has been interrupted
  },
  x: false,
  y: true
});
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
	scrollBehavior (to, from, savedPosition) {
		// 返回期望的滚动位置
		if (savedPosition) {
      return savedPosition
    } else {
			return { x: 0, y: 0 };
    }
	}
})

router.beforeEach((to, from, next) => {
  // 判断跳转的页面
  if (to.name === 'details' ||  to.name === 'reportRead'||  to.name === 'suppNeedDetail'  ) {
    if (localStorage.getItem('accessToken') != null) {
      next()
    } else {
			Vue.prototype.$message.warning("请登录后查看");
    }
  } else {
   // 其他页直接跳转
   next()
  }
})


export default router
